import { APP_DOMAIN, VERCEL_URL } from 'constants/process'
import { withAuth } from 'hocs/withAuth'
import { withAuthServerSideProps } from 'hocs/withAuth/withAuthServerSideProps'
import dynamic from 'next/dynamic'
import { adsAxiosInstance } from 'api/adsAxiosClient'

const JobBoardPage = dynamic(() =>
  import('containers/JobBoardPage').then((res) => res.JobBoardPage)
)

export default withAuth(JobBoardPage, { LoggedOutComponent: JobBoardPage })

export const getServerSideProps: ReturnType<typeof withAuthServerSideProps> =
  withAuthServerSideProps(async ({ req, params }) => {
    const domain = APP_DOMAIN || VERCEL_URL
    const subdomain =
      req?.headers?.host === domain
        ? ''
        : req?.headers?.host.replace(`.${domain}`, '')

    const companySlug = subdomain || (params.companyName as string)

    if (!companySlug) return { notFound: true }

    const company = (
      await adsAxiosInstance.post(`/companies`, {
        company: {
          slug: companySlug,
          full_enrichment: true,
          companies_per_page: 1,
          page: 1,
        },
      })
    ).data?.results?.[0]

    return {
      company,
      companySlug,
      title: `Fintros | ${company?.name || companySlug}`,
      description: company?.description,
      image: company?.logo_url,
    }
  })
